var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-xs-12 col-sm-12 col-md-2 col-lg-2" },
      [
        _c("c-table", {
          ref: "gridworker",
          attrs: {
            title: "설문 대상자",
            columns: _vm.gridworker.columns,
            gridHeight: _vm.gridworker.height,
            data: _vm.surveies,
            columnSetting: false,
            usePaging: false,
            isExcelDown: false,
            isFullScreen: false,
          },
          on: { rowClick: _vm.rowClick },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "col-xs-12 col-sm-12 col-md-10 col-lg-10" },
      [
        [
          _c(_vm.component, {
            tag: "component",
            attrs: { popupParam: _vm.pageParam },
            on: {
              "update:popupParam": function ($event) {
                _vm.pageParam = $event
              },
              "update:popup-param": function ($event) {
                _vm.pageParam = $event
              },
            },
          }),
        ],
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }