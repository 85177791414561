<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
      <c-table
        ref="gridworker"
        title="설문 대상자"
        :columns="gridworker.columns"
        :gridHeight="gridworker.height"
        :data="surveies"
        :columnSetting="false"
        :usePaging="false"
        :isExcelDown="false"
        :isFullScreen="false"
        @rowClick="rowClick"
      >
      </c-table>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-10 col-lg-10">
      <template>
        <component
          :is="component"
          :popupParam.sync="pageParam"
        />
      </template>
    </div>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'symptomSurveyStatusDetail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        heaInvestigationPlanId: '',
        heaMuscleWorkerSurveyId: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      component: null,
      pageParam: {
        heaMuscleWorkerSurveyId: '',
        readOnly: true,
      },
      surveies: [],
      editable: true,
      listUrl: '',
      gridworker: {
        columns: [
          {
            name: 'deptName',
            field: 'deptName',
            label: 'LBLDEPT',
            align: 'center',
            style: 'width:40%',
            sortable: false,
          },
          {
            name: 'userName',
            field: 'userName',
            label: 'LBLNAME',
            align: 'center',
            sortable: false,
          },
        ],
        height: ''
      },
    };
  },
  computed: {
    tabHeight() {
      return String(this.contentHeight - 36);
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.hea.muscleSystem.muscleSurvey.list.url
      // code setting
      // list setting
      this.pageParam.heaMuscleWorkerSurveyId = this.popupParam.heaMuscleWorkerSurveyId;
      this.gridworker.height = (window.innerHeight - (100)) + 'px';
      this.component = () => import(`${'./symptomSurveyDetail.vue'}`);
      this.getDetail();
    },
    getDetail() {
      this.$http.url = this.listUrl
      this.$http.type = 'GET';
      this.$http.param = {
        heaInvestigationPlanId: this.popupParam.heaInvestigationPlanId
      };
      this.$http.request((_result) => {
        this.surveies = _result.data
      },);
    },
    rowClick(row) {
      this.$set(this.pageParam, 'heaMuscleWorkerSurveyId', row.heaMuscleWorkerSurveyId)
    }
  }
};
</script>
